<template lang="pug">
  nav.navigation.menu__items(@click='removeMenu')
    ul.navigation__wr
      li.navigation__list
        a(v-scroll-to="'#header'")(@click='scrollCustom').navigation__item.one Home

      li.navigation__list
        a(v-scroll-to="'#advantages'")(@click='scrollCustom').navigation__item.two Advantages

      li.navigation__list
        a(v-scroll-to="'#use'")(@click='scrollCustom').navigation__item.three About
</template>

<script>

export default {
  name: 'NavigationBlock',
  methods: {
    scrollCustom(event) {
      document.querySelectorAll('.navigation__item').forEach((el) => {
        el.classList.remove('active')
        event.target.classList.add('active')
      })
    },
    removeMenu() {
      document.querySelector('.menu__items').classList.remove('menu__items--active')
      document.querySelector('.header__burger').classList.remove('header__burger--active')
    }
  }
}

</script>
